import addToMailchimp from "gatsby-plugin-mailchimp";
import React, { useState } from "react";

import styled from "@emotion/styled";
import mediaqueries from "../../styles/media";

const Subscription: React.FunctionComponent<{ placeholder, buttonText }> = ({ buttonText, placeholder = 'elon@x.com' }) => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [mailchimpPath, setMailchimpPath] = useState('Home page hero')
  const [subscribed, setSubscribed] = useState(false);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const path = location.pathname === '/' ? 'Home page hero' : `Other articles like: ${location.pathname}`
    setMailchimpPath(path)

    addToMailchimp(email, { PATH: mailchimpPath })
      .then(data => {
        if (data.result === "error") {
          throw data;
        }

        setSubscribed(true);
        setEmail("");

        setTimeout(() => {
          setSubscribed(false);
        }, 6000);
      })
      .catch(error => {
        setError(error.msg);
      });
  }

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.currentTarget.value);
    setError("");
  }

  return (
    <Form onSubmit={handleSubmit} hasError={error}>
      <Input
        placeholder={placeholder}
        aria-label="Email"
        id={buttonText !== 'Subscribe' ? 'hero-email' : undefined}
        name="email"
        type="email"
        value={email}
        onChange={handleEmailChange}
        hasError={error}
      />
      <Button
        type="submit"
        id={buttonText !== 'Subscribe' ? 'hero-button' : undefined}
        hasError={error}
        subscribed={subscribed}
        disabled={subscribed}
      >
        {subscribed ? <CheckMarkIcon /> : buttonText}
      </Button>
      {error && <Error dangerouslySetInnerHTML={{ __html: error }} />}
    </Form>
  );
};

export default Subscription;

const Form = styled.form<{ hasError: string }>`
  position: relative;

  &::after {
    position: absolute;
    left: 21px;
    top: 10px;
    color: ${p => (p.hasError ? p.theme.colors.error : p.theme.colors.accent)};

    ${mediaqueries.tablet`
      left: 34px;
      top: 11px;
    `}
  }

  #hero-email {
    margin: 0px 18px 18px 0px;
  }

  #hero-button {
    margin: 0px;
  }

  ${p => p.hasError &&
    mediaqueries.phone_up`
      padding-bottom: 35px;
    `
  }
  ${p => p.hasError &&
    mediaqueries.desktop_up`
      padding-bottom: 0px;
    `
  }

`;

const Input = styled.input<{ hasError: string }>`
  position: relative;
  background: ${p =>
    p.hasError
      ? p.theme.colors.errorBackground
      : p.theme.colors.inputBackground};
  border-radius: 35px;
  border: none;
  padding: 19px 21px 19px 35px;
  width: 471px;
  color: ${p => p.theme.colors.primary};

  ::placeholder {
    color: ${p => p.theme.colors.track};
    opacity: 1;
  }

  :-ms-input-placeholder {
    color: ${p => p.theme.colors.track};
  }

  ::-ms-input-placeholder {
    color: ${p => p.theme.colors.track};
  }

  ${mediaqueries.tablet`
    width: calc(100% - 36px);
    margin: 0 18px;
    padding: 14px 14px 14px 30px;
    margin-bottom: 15px;
  `}
`;

const Button = styled.button<{ hasError: string; subscribed: boolean }>`
  position: absolute;
  left: 306px;
  top: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 161px;
  height: 48px;
  border: 1px solid
    ${p => (p.hasError ? p.theme.colors.error : p.theme.colors.accent)};
  color: ${p => (p.hasError ? p.theme.colors.error : p.theme.colors.accent)};
  background: ${p => (p.subscribed ? p.theme.colors.accent : 'transparent')};
  font-weight: 600;
  border-radius: 35px;
  letter-spacing: 0.42px;
  transition: border-color 0.2s var(--ease-in-out-quad),
    background 0.2s var(--ease-in-out-quad), color 0.2s var(--ease-in-out-quad);

  &:hover {
    background: ${p =>
      p.hasError ? p.theme.colors.error : p.theme.colors.accent};
    color: ${p => p.theme.colors.background};
  }

  &[disabled] {
    cursor: not-allowed;
  }

  svg * {
    fill: ${p => p.theme.colors.background};
  }

  ${p => mediaqueries.tablet`
    position: relative;
    height: 45px;
    width: calc(100% - 32px);
    margin: 0 auto;
    top: 0;
    left: 0;
  `}
`;

const Error = styled.div`
  position: absolute;
  left: 15px;
  bottom: -8px;
  color: ${p => p.theme.colors.error};
  font-size: 12px;

  a {
    color: ${p => p.theme.colors.error};
    text-decoration: underline;
  }

  ${mediaqueries.tablet`
    left: 10px;
    top: 120px;
  `}

  ${mediaqueries.phablet`
    top: 120px;
    bottom: 0px;
    left: 0px;
    padding: 0px 20px;
  `}
`;

const CheckMarkIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.00016 16.1698L4.83016 11.9998L3.41016 13.4098L9.00016 18.9998L21.0002 6.99984L19.5902 5.58984L9.00016 16.1698Z"
      fill="#08080B"
    />
  </svg>
);
